import {
  PlanEvoBareMetal,
  PlanIdBareMetal,
} from 'src/components/TariffPlans/types';

import { price } from './data';

const evoBareMetal1: PlanEvoBareMetal = {
  planId: PlanIdBareMetal.E5_2699_22CPU_64GBRAM_2xSAS900GB,
  planStatus: 'success',
  price: price,
  productType: 'evolutionBareMetal',
  description: 'Для решения задач стартапов, малого и среднего бизнеса',
  data: {
    productQuantity: 1,
    nameFlavour:
      '2 x Intel Broadwell EP Xeon E5-2699 v4 22 ядра 2.2ГГц; RAM: 64ГБ; HDD: 2 x 900ГБ SAS',
    osName: 'Ubuntu',
    osVersion: '20.04',
    bindingPublickIpAddress: true,
  },
};

const evoBareMetal2: PlanEvoBareMetal = {
  planId: PlanIdBareMetal.E5_2690_12CPU_128GBRAM_4xSATA2TB,
  planStatus: 'success',
  price: price,
  productType: 'evolutionBareMetal',
  description: 'Для корпоративных приложений',
  data: {
    productQuantity: 1,
    nameFlavour:
      '2 x Intel Haswell EP Xeon E5-2690 v3 12 ядер 2.6ГГц; RAM: 128ГБ; HDD: 4 x 2ТБ SATA',
    osName: 'Ubuntu',
    osVersion: '20.04',
    bindingPublickIpAddress: true,
  },
};

const evoBareMetal3: PlanEvoBareMetal = {
  planId: PlanIdBareMetal.XEONGOLD6161_22CPU_64GBRAM_4xSAS800GB,
  planStatus: 'success',
  price: price,
  productType: 'evolutionBareMetal',
  description: 'Для обработки Big Data',
  data: {
    productQuantity: 1,
    nameFlavour:
      '2 x Intel Skylake-SP Xeon Gold 6161 22 ядра 2.2ГГц; RAM: 64ГБ; SSD: 4 x 800ГБ SAS',
    osName: 'Ubuntu',
    osVersion: '20.04',
    bindingPublickIpAddress: true,
  },
};

export { evoBareMetal1, evoBareMetal2, evoBareMetal3 };
