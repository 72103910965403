import cn from 'classnames';
import { useRouter } from 'next/router';
import { ACTIONS } from 'src/components/TariffPlans/actions';
import {
  useTariffPlansDispatch,
  useTariffPlansState,
} from 'src/components/TariffPlans/tariff-plans-context';
import { TariffsProduct } from 'src/components/TariffPlans/types';
import { Button } from 'src/uikit/Button';

import s from './CalcCard.module.scss';

type CalcCardProps = {
  product: TariffsProduct;
  planIndex: number;
};

export function CalcCard({ product, planIndex }: CalcCardProps) {
  const router = useRouter();
  const state = useTariffPlansState();
  const dispatch = useTariffPlansDispatch();

  const cardClassName = cn(s.card, {
    [s.vm]: product === 'evolutionCloudServer',
    [s.s3]: product === 'evolutionStorageS3',
    [s.kuber]: product === 'evolutionKubernetes',
  });

  function getAnalyticId() {
    switch (product) {
      case 'evolutionCloudServer':
        return 'evolution-cloud-server-card-calculator';
      case 'evolutionKubernetes':
      case 'evolutionKubernetesLandingPage':
        return 'evolution-kuber-card-calculator';
      case 'evolutionStorageS3':
        return 'evolution-storage-card-calculator';
      case 'evolutionBareMetal':
        return 'evolution-bare-metal-card-calculator';
      case 'evolutionCloudServerGpu':
        return 'evolution-cloud-server-gpu-card-calculator';
      case 'evolutionPostgreSql':
        return 'evolution-postgre-sql-card-calculator';
    }
  }

  const analyticId = getAnalyticId();

  const parseText = (str: string) => {
    return str.replace(/&nbsp;/g, '\u00A0');
  };

  function getCardTitle() {
    switch (product) {
      case 'evolutionCloudServer':
        return parseText(
          'Соберите свою виртуальную машину в&nbsp;калькуляторе',
        );
      case 'evolutionKubernetes':
        return parseText('Соберите свой Kubernetes в&nbsp;калькуляторе');
      case 'evolutionStorageS3':
        return parseText(
          'Соберите свое Объектное хранилище S3 в&nbsp;калькуляторе',
        );
      case 'evolutionBareMetal':
        return parseText(
          'Выберите свою конфигурацию Bare&nbsp;Metal в&nbsp;калькуляторе',
        );
      case 'evolutionCloudServerGpu':
        return parseText(
          'Выберите свою виртуальную машину GPU в&nbsp;калькуляторе',
        );
      case 'evolutionPostgreSql':
        return parseText(
          'Соберите свой кластер баз данных в&nbsp;калькуляторе',
        );
      default:
        return parseText('Соберите свою конфигурацию в&nbsp;калькуляторе');
    }
  }

  const isActive = state.activePlanIndex === planIndex;

  return (
    <div
      key="calculator"
      className={`${cardClassName} ${isActive ? s.cardHovered : ''}`}
      onMouseOver={() =>
        dispatch({
          type: ACTIONS.UPD_ACTIVE_PLAN_INDEX,
          payload: planIndex,
        })
      }
      onMouseLeave={() =>
        dispatch({
          type: ACTIONS.UPD_ACTIVE_PLAN_INDEX,
          payload: 0,
        })
      }
    >
      <div className={s.cardTitle}>{getCardTitle()}</div>
      <Button
        className={s.button}
        variant={Button.variants.Outline}
        onClick={() => router.push('/calculator' + '?product=' + product)}
        analyticsOptions={{
          action: 'click',
          clickZone: 'body',
          clickElement: 'button',
          clickContent: 'В калькулятор',
          uniqueId: analyticId,
          transitionType: 'inside-link',
        }}
      >
        Выбрать
      </Button>
      <img
        src="/svg/components/Calculator/calculator.png"
        alt="calculator"
        className={s.img}
      />
    </div>
  );
}
