import { useState } from 'react';
import { ACTIONS } from 'src/components/TariffPlans/actions';
import { useTariffPlansDispatch } from 'src/components/TariffPlans/tariff-plans-context';
import { Tabs } from 'src/uikit/Tabs';

import s from './ProductChanger.module.scss';

type tabIdType =
  | 'evoCloudServerMainPage'
  | 'evoS3MainPage'
  | 'evoKuberMainPage'
  | 'evoBareMetalMainPage';

function ProductChanger() {
  const dispatch = useTariffPlansDispatch();
  const [tabId, setTabId] = useState<tabIdType>('evoCloudServerMainPage');

  return (
    <div className={s.products}>
      <Tabs
        className={s.tabs}
        tabs={[
          {
            id: 'evoCloudServerMainPage',
            title: 'Виртуальная машина',
            slug: 'evoCloudServerMainPage',
            icon: (
              <img
                src="/svg/components/Calculator/vm.svg"
                alt="calculator"
                className={s.img}
              />
            ),
          },
          {
            id: 'evoS3MainPage',
            title: 'Объектное хранилище S3',
            slug: 'evoS3MainPage',
            icon: (
              <img
                src="/svg/components/Calculator/s3.svg"
                alt="calculator"
                className={s.img}
              />
            ),
          },
          {
            id: 'evoKuberMainPage',
            title: 'Управляемые кластеры Kubernetes',
            slug: 'evoKuberMainPage',
            icon: (
              <img
                src="/svg/components/Calculator/kubernetes.svg"
                alt="calculator"
                className={s.img}
              />
            ),
          },
          // {
          //   id: 'evoBareMetalMainPage',
          //   title: 'Bare Metal',
          //   slug: 'evoBareMetalMainPage',
          //   icon: (
          //     <img
          //       src="/svg/components/Calculator/kubernetes.svg"
          //       alt="calculator"
          //       className={s.img}
          //     />
          //   ),
          // },
        ]}
        onClick={(tab) => {
          setTabId(tab.id as tabIdType);
          dispatch({
            type: ACTIONS.UPD_ACTIVE_PLANS_SET,
            payload: tab.id as tabIdType,
          });
        }}
        activeTab={tabId}
      />
    </div>
  );
}

export { ProductChanger };
