import { PlanEvoKuber } from 'src/components/TariffPlans/types';

import { price } from './data';

const evoKuber1: PlanEvoKuber = {
  planId: 'mn1wn1d10',
  planStatus: 'success',
  price: price,
  productType: 'evolutionKubernetes',
  description: 'Минимальная рабочая нагрузка',
  data: {
    productQuantity: 1,
    masterNode: {
      nodeConfig: '2 4',
      nodeCount: '1',
    },
    workerNode: {
      guaranteedPart: '10',
      vCpuCount: '4',
      ramAmount: '8',
      nodeCount: 1,
      diskSize: 10,
    },
    workerNodeNeeded: true,
    bindingPublickIpAddress: true,
  },
};

const evoKuber2: PlanEvoKuber = {
  planId: 'mn1wn3d10',
  planStatus: 'success',
  price: price,
  productType: 'evolutionKubernetes',
  description: 'Небольшие приложения без высоких требований',
  data: {
    productQuantity: 1,
    masterNode: {
      nodeConfig: '2 4',
      nodeCount: '1',
    },
    workerNode: {
      guaranteedPart: '100',
      vCpuCount: '4',
      ramAmount: '8',
      nodeCount: 3,
      diskSize: 10,
    },
    workerNodeNeeded: true,
    bindingPublickIpAddress: true,
  },
};

const evoKuber3: PlanEvoKuber = {
  planId: 'mn3wn3d16',
  planStatus: 'success',
  price: price,
  productType: 'evolutionKubernetes',
  description: 'Полная отказоустойчивая конфигурация',
  data: {
    productQuantity: 1,
    masterNode: {
      nodeConfig: '2 4',
      nodeCount: '3',
    },
    workerNode: {
      guaranteedPart: '100',
      vCpuCount: '4',
      ramAmount: '8',
      nodeCount: 3,
      diskSize: 16,
    },
    workerNodeNeeded: true,
    bindingPublickIpAddress: true,
  },
};

export { evoKuber1, evoKuber2, evoKuber3 };
