export enum ACTIONS {
  UPD_TARIFF_PLANS_TYPE = 'UPD_TARIFF_PLANS_TYPE',
  UPD_CURRENT_PRICE_PERIOD = 'UPD_CURRENT_PRICE_PERIOD',
  UPD_ACTIVE_PLAN_INDEX = 'UPD_ACTIVE_PLAN_INDEX',
  UPD_ACTIVE_PLANS_SET = 'UPD_ACTIVE_PLANS_SET',
  //
  UPD_PLAN_EVOLUTION_CLOUD_SERVER_INPUTS = 'UPD_PLAN_EVOLUTION_CLOUD_SERVER_INPUTS',
  UPD_PLAN_EVOLUTION_CLOUD_SERVER_STATUS = 'UPD_PLAN_EVOLUTION_CLOUD_SERVER_STATUS',
  UPD_PLAN_EVOLUTION_CLOUD_SERVER_PRICE = 'UPD_PLAN_EVOLUTION_CLOUD_SERVER_PRICE',
  //
  UPD_PLAN_EVOLUTION_STORAGE_S3_STATUS = 'UPD_PLAN_EVOLUTION_STORAGE_S3_STATUS',
  UPD_PLAN_EVOLUTION_STORAGE_S3_PRICE = 'UPD_PLAN_EVOLUTION_STORAGE_S3_PRICE',
  //
  UPD_PLAN_EVOLUTION_KUBERNETES_STATUS = 'UPD_PLAN_EVOLUTION_KUBERNETES_STATUS',
  UPD_PLAN_EVOLUTION_KUBERNETES_PRICE = 'UPD_PLAN_EVOLUTION_KUBERNETES_PRICE',
  //
  UPD_PLAN_EVOLUTION_BARE_METAL_STATUS = 'UPD_PLAN_EVOLUTION_BARE_METAL_STATUS',
  UPD_PLAN_EVOLUTION_BARE_METAL_PRICE = 'UPD_PLAN_EVOLUTION_BARE_METAL_PRICE',
  //
  UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_STATUS = 'UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_STATUS',
  UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_PRICE = 'UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_PRICE',
  UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_INPUTS = 'UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_INPUTS',
  //
  UPD_PLAN_EVOLUTION_POSTGRE_SQL_STATUS = 'UPD_PLAN_EVOLUTION_POSTGRE_SQL_STATUS',
  UPD_PLAN_EVOLUTION_POSTGRE_SQL_PRICE = 'UPD_PLAN_EVOLUTION_POSTGRE_SQL_PRICE',
  //
  UPD_PLAN_EVOLUTION_KUBERNETES_LP_STATUS = 'UPD_PLAN_EVOLUTION_KUBERNETES_LP_STATUS',
  UPD_PLAN_EVOLUTION_KUBERNETES_LP_PRICE = 'UPD_PLAN_EVOLUTION_KUBERNETES_LP_PRICE',
}

export type PriceActionType =
  | ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_PRICE
  | ACTIONS.UPD_PLAN_EVOLUTION_STORAGE_S3_PRICE
  | ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_PRICE
  | ACTIONS.UPD_PLAN_EVOLUTION_BARE_METAL_PRICE
  | ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_PRICE
  | ACTIONS.UPD_PLAN_EVOLUTION_POSTGRE_SQL_PRICE
  | ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_LP_PRICE;

export type StatusActionType =
  | ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_STATUS
  | ACTIONS.UPD_PLAN_EVOLUTION_STORAGE_S3_STATUS
  | ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_STATUS
  | ACTIONS.UPD_PLAN_EVOLUTION_BARE_METAL_STATUS
  | ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_STATUS
  | ACTIONS.UPD_PLAN_EVOLUTION_POSTGRE_SQL_STATUS
  | ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_LP_STATUS;
