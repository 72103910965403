import { Product } from 'src/components/Calculator/types';

// импорт data продуктов из калькулятора так как их структура должна быть одинаковой
// что бы бэк калькулятора мог расчитывать цену
import { ACTIONS } from '../actions';

export interface Price {
  priceHourNds: number;
  priceDayNds: number;
  priceMonthNds: number;
}

export type TariffPlanCards<K> = [K, K, K];

export type PlansStatus = 'loading' | 'success' | 'error';

export type PlanIdCloudServer =
  | 'free-tier'
  | '1vCPU1RAM10part'
  | '4vCPU8RAM10part'
  | '1vCPU2RAM'
  | '4vCPU8RAM'
  | '8vCPU16RAM'
  | '8vCPU32RAM';

export type PlanIdKuber = 'mn1wn1d10' | 'mn1wn3d10' | 'mn3wn3d16';
export type PlanIdKuberLandingPage =
  | 'part10mn1c24wn1c24'
  | 'part10mn1c24wn1c48'
  | 'part30mn1c24wn1c24'
  | 'part30mn3c24wn3c24';
export type PlanIdStorage = 'free-tier' | 'cold1000' | 'ice3000';
export enum PlanIdBareMetal {
  E5_2699_22CPU_64GBRAM_2xSAS900GB = '2xE5-2699-22CPU-64GBRAM-2xSAS900GB',
  E5_2690_12CPU_128GBRAM_4xSATA2TB = '2xE5-2690-12CPU-128GBRAM-4xSATA2TB',
  XEONGOLD6161_22CPU_64GBRAM_4xSAS800GB = '2xXEONGOLD6161-22CPU-64GBRAM-4xSAS800GB',
}

export type PlanIdCloudServerGpu =
  | '1gpu-4vcpu-64ram'
  | '4gpu-16vcpu-256ram'
  | '16gpu-64vcpu-1024ram';

export type PlanIdPostgreSql =
  | '05cpu1ram10gb'
  | '2cpu4ram40gb'
  | '4cpu16ram100gbå';

export type CurrentPricePeriod =
  | 'priceHourNds'
  | 'priceDayNds'
  | 'priceMonthNds';

export type TariffPlansType = 'mainPage' | 'product';

// PlansSet это НЕ обязательно набор тарифов одного конкретного продукта, потенциально там могут быть и разные продукты
export type ActivePlansSet =
  | 'evoCloudServerMainPage'
  | 'evoKuberMainPage'
  | 'evoS3MainPage'
  | 'evoBareMetalMainPage'
  | 'evoCloudServerGpuMainPage'
  | 'evoPostgreSqlMainPage'
  | 'evoKuberLandingPage';

export type TariffsProduct =
  | 'evolutionCloudServer'
  | 'evolutionKubernetes'
  | 'evolutionKubernetesLandingPage'
  | 'evolutionStorageS3'
  | 'evolutionBareMetal'
  | 'evolutionCloudServerGpu'
  | 'evolutionPostgreSql';

export type TariffsProductType =
  | 'evolutionCloudServer'
  | 'evolutionCloudServerFreeTier'
  | 'evolutionKubernetes'
  | 'evolutionStorageS3'
  | 'evolutionStorageS3FreeTier'
  | 'evolutionBareMetal'
  | 'evolutionCloudServerGpu'
  | 'evolutionPostgreSql';

export type PlanId =
  | PlanIdCloudServer
  | PlanIdKuber
  | PlanIdStorage
  | PlanIdBareMetal
  | PlanIdCloudServerGpu
  | PlanIdPostgreSql
  | PlanIdKuberLandingPage;

export interface StateType {
  tariffPlansType: TariffPlansType;
  currentPricePeriod: CurrentPricePeriod;
  activePlansSet: ActivePlansSet;
  activePlanIndex: number;
  plans: Plans;
}

interface PlansMainPage {
  evolutionCloudServer: PlanEvoCloudServer[];
  evolutionKubernetes: PlanEvoKuber[];
  evolutionStorageS3: PlanEvoStorage[];
  evolutionBareMetal: PlanEvoBareMetal[];
  evolutionCloudServerGpu: PlanEvoCloudServerGpu[];
  evolutionPostgreSql: PlanEvoPostgreSql[];
  evolutionKubernetesLandingPage: PlanEvoKuberLandingPage[];
}

interface PlansProducts {
  evolutionCloudServer: PlanEvoCloudServer[];
  evolutionKubernetes: PlanEvoKuber[];
  evolutionStorageS3: PlanEvoStorage[];
  evolutionBareMetal: PlanEvoBareMetal[];
  evolutionCloudServerGpu: PlanEvoCloudServerGpu[];
  evolutionPostgreSql: PlanEvoPostgreSql[];
  evolutionKubernetesLandingPage: PlanEvoKuberLandingPage[];
}

interface Plans {
  mainPage: PlansMainPage;
  product: PlansProducts;
}

export interface TariffPlansInitialData {
  mainPage: StateType;
  product: StateType;
}

export interface PlanEvoCloudServer {
  planId: PlanIdCloudServer;
  planStatus: PlansStatus;
  price: Price;
  productType: 'evolutionCloudServer' | 'evolutionCloudServerFreeTier';
  description: string;
  data:
    | Product<'evolutionCloudServer'>['data']
    | Product<'evolutionCloudServerFreeTier'>['data'];
}

export interface PlanEvoKuber {
  planId: PlanIdKuber;
  planStatus: PlansStatus;
  price: Price;
  productType: 'evolutionKubernetes';
  description: string;
  data: Product<'evolutionKubernetes'>['data'];
}

export interface PlanEvoKuberLandingPage {
  planId: PlanIdKuberLandingPage;
  planStatus: PlansStatus;
  price: Price;
  productType: 'evolutionKubernetes';
  description: string;
  data: Product<'evolutionKubernetes'>['data'];
}

export interface PlanEvoStorage {
  planId: PlanIdStorage;
  planStatus: PlansStatus;
  price: Price;
  productType: 'evolutionStorageS3' | 'evolutionStorageS3FreeTier';
  description: string;
  data:
    | Product<'evolutionStorageS3'>['data']
    | Product<'evolutionStorageS3FreeTier'>['data'];
}

export interface PlanEvoBareMetal {
  planId: PlanIdBareMetal;
  planStatus: PlansStatus;
  price: Price;
  productType: 'evolutionBareMetal';
  description: string;
  data: Product<'evolutionBareMetal'>['data'];
}

export interface PlanEvoCloudServerGpu {
  planId: PlanIdCloudServerGpu;
  planStatus: PlansStatus;
  price: Price;
  productType: 'evolutionCloudServerGpu';
  description: string;
  data: Product<'evolutionCloudServerGpu'>['data'];
}

export interface PlanEvoPostgreSql {
  planId: PlanIdPostgreSql;
  planStatus: PlansStatus;
  price: Price;
  productType: 'evolutionPostgreSql';
  description: string;
  data: Product<'evolutionPostgreSql'>['data'];
}

// общие экшены
interface UPD_CURRENT_PRICE_PERIOD {
  type: ACTIONS.UPD_CURRENT_PRICE_PERIOD;
  payload: CurrentPricePeriod;
}

interface UPD_TARIFF_PLANS_TYPE {
  type: ACTIONS.UPD_TARIFF_PLANS_TYPE;
  payload: TariffPlansType;
}

interface UPD_ACTIVE_PLAN_INDEX {
  type: ACTIONS.UPD_ACTIVE_PLAN_INDEX;
  payload: number;
}

interface UPD_ACTIVE_PLANS_SET {
  type: ACTIONS.UPD_ACTIVE_PLANS_SET;
  payload: ActivePlansSet;
}

//

interface UPD_PLAN_EVOLUTION_CLOUD_SERVER_PRICE {
  type: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_PRICE;
  payload: {
    planId: PlanIdCloudServer;
    price: Price;
  };
}

interface UPD_PLAN_EVOLUTION_CLOUD_SERVER_STATUS {
  type: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_STATUS;
  payload: {
    planId: PlanIdCloudServer;
    planStatus: PlansStatus;
  };
}

interface UPD_PLAN_EVOLUTION_CLOUD_SERVER_INPUTS {
  type: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_INPUTS;
  payload: {
    planId: PlanIdCloudServer;
    data: {
      networkIsNeeded: boolean;
    };
  };
}

//

interface UPD_PLAN_EVOLUTION_STORAGE_S3_PRICE {
  type: ACTIONS.UPD_PLAN_EVOLUTION_STORAGE_S3_PRICE;
  payload: {
    planId: PlanIdStorage;
    price: Price;
  };
}

interface UPD_PLAN_EVOLUTION_STORAGE_S3_STATUS {
  type: ACTIONS.UPD_PLAN_EVOLUTION_STORAGE_S3_STATUS;
  payload: {
    planId: PlanIdStorage;
    planStatus: PlansStatus;
  };
}

//

interface UPD_PLAN_EVOLUTION_KUBERNETES_PRICE {
  type: ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_PRICE;
  payload: {
    planId: PlanIdKuber;
    price: Price;
  };
}

interface UPD_PLAN_EVOLUTION_KUBERNETES_STATUS {
  type: ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_STATUS;
  payload: {
    planId: PlanIdKuber;
    planStatus: PlansStatus;
  };
}

interface UPD_PLAN_EVOLUTION_KUBERNETES_LP_PRICE {
  type: ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_LP_PRICE;
  payload: {
    planId: PlanIdKuberLandingPage;
    price: Price;
  };
}

interface UPD_PLAN_EVOLUTION_KUBERNETES_LP_STATUS {
  type: ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_LP_STATUS;
  payload: {
    planId: PlanIdKuberLandingPage;
    planStatus: PlansStatus;
  };
}

// evolutionBareMetal
interface UPD_PLAN_EVOLUTION_BARE_METAL_PRICE {
  type: ACTIONS.UPD_PLAN_EVOLUTION_BARE_METAL_PRICE;
  payload: {
    planId: PlanIdBareMetal;
    price: Price;
  };
}

interface UPD_PLAN_EVOLUTION_BARE_METAL_STATUS {
  type: ACTIONS.UPD_PLAN_EVOLUTION_BARE_METAL_STATUS;
  payload: {
    planId: PlanIdBareMetal;
    planStatus: PlansStatus;
  };
}

interface UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_STATUS {
  type: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_STATUS;
  payload: {
    planId: PlanIdCloudServerGpu;
    planStatus: PlansStatus;
  };
}

interface UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_PRICE {
  type: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_PRICE;
  payload: {
    planId: PlanIdCloudServerGpu;
    price: Price;
  };
}

interface UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_INPUTS {
  type: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_INPUTS;
  payload: {
    planId: PlanIdCloudServerGpu;
    data: {
      networkIsNeeded: boolean;
    };
  };
}

//

interface UPD_PLAN_EVOLUTION_POSTGRE_SQL_STATUS {
  type: ACTIONS.UPD_PLAN_EVOLUTION_POSTGRE_SQL_STATUS;
  payload: {
    planId: PlanIdPostgreSql;
    planStatus: PlansStatus;
  };
}

interface UPD_PLAN_EVOLUTION_POSTGRE_SQL_PRICE {
  type: ACTIONS.UPD_PLAN_EVOLUTION_POSTGRE_SQL_PRICE;
  payload: {
    planId: PlanIdPostgreSql;
    price: Price;
  };
}

// export Action and Dispatch
export type Action =
  | UPD_TARIFF_PLANS_TYPE
  | UPD_CURRENT_PRICE_PERIOD
  | UPD_ACTIVE_PLAN_INDEX
  | UPD_ACTIVE_PLANS_SET
  //
  | UPD_PLAN_EVOLUTION_CLOUD_SERVER_INPUTS
  | UPD_PLAN_EVOLUTION_CLOUD_SERVER_STATUS
  | UPD_PLAN_EVOLUTION_CLOUD_SERVER_PRICE
  //
  | UPD_PLAN_EVOLUTION_STORAGE_S3_STATUS
  | UPD_PLAN_EVOLUTION_STORAGE_S3_PRICE
  //
  | UPD_PLAN_EVOLUTION_KUBERNETES_STATUS
  | UPD_PLAN_EVOLUTION_KUBERNETES_PRICE
  //
  | UPD_PLAN_EVOLUTION_KUBERNETES_LP_STATUS
  | UPD_PLAN_EVOLUTION_KUBERNETES_LP_PRICE
  //
  | UPD_PLAN_EVOLUTION_BARE_METAL_PRICE
  | UPD_PLAN_EVOLUTION_BARE_METAL_STATUS
  //
  | UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_STATUS
  | UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_PRICE
  | UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_INPUTS
  //
  | UPD_PLAN_EVOLUTION_POSTGRE_SQL_STATUS
  | UPD_PLAN_EVOLUTION_POSTGRE_SQL_PRICE;

export type StatusAction =
  | UPD_PLAN_EVOLUTION_CLOUD_SERVER_STATUS
  | UPD_PLAN_EVOLUTION_STORAGE_S3_STATUS
  | UPD_PLAN_EVOLUTION_KUBERNETES_STATUS
  | UPD_PLAN_EVOLUTION_BARE_METAL_STATUS
  | UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_STATUS
  | UPD_PLAN_EVOLUTION_POSTGRE_SQL_STATUS;

export type PriceAction =
  | UPD_PLAN_EVOLUTION_CLOUD_SERVER_PRICE
  | UPD_PLAN_EVOLUTION_STORAGE_S3_PRICE
  | UPD_PLAN_EVOLUTION_KUBERNETES_PRICE
  | UPD_PLAN_EVOLUTION_BARE_METAL_PRICE
  | UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_PRICE
  | UPD_PLAN_EVOLUTION_POSTGRE_SQL_PRICE;

export type Dispatch = (action: Action) => void;
