import { PlanIdBareMetal } from '../types';

export function getTotalCpu(planId: PlanIdBareMetal) {
  if (planId === PlanIdBareMetal.E5_2699_22CPU_64GBRAM_2xSAS900GB) {
    return '2 x Intel Broadwell EP Xeon E5-2699 v4 2.2 ГГц 22 ядра';
  } else if (planId === PlanIdBareMetal.E5_2690_12CPU_128GBRAM_4xSATA2TB) {
    return '2 x Intel Skylake-SP Xeon Gold 6161 2.2 ГГц 22 ядра';
  } else if (planId === PlanIdBareMetal.XEONGOLD6161_22CPU_64GBRAM_4xSAS800GB) {
    return '2 x Intel Haswell EP Xeon E5-2690 v3 2.6 ГГц 12 ядер';
  }

  return planId;
}

export function getTotalRam(ram: string) {
  return `${ram.split(' ')[1].replace(/\D/g, '')} ГБ DDR`;
}

export function getTotalDisk(disk: string) {
  const splittedValuesDisk = disk.split(' ');
  const diskType = splittedValuesDisk.splice(0, 1)[0].replace(':', '');
  splittedValuesDisk.splice(3, 0, diskType);

  return splittedValuesDisk.join(' ');
}
