import cn from 'classnames';
import { useEffect } from 'react';
import { ProductType } from 'src/components/Calculator/types';
import { ACTIONS } from 'src/components/TariffPlans/actions';
import { Disclaimer } from 'src/components/TariffPlans/components/Disclaimer';
import { ProductChanger } from 'src/components/TariffPlans/components/ProductChanger';
import { TimePeriodChanger } from 'src/components/TariffPlans/components/TimePeriodChanger';
import { useTariffPlansDispatch } from 'src/components/TariffPlans/tariff-plans-context';
import {
  TariffPlansProvider,
  useTariffPlansState,
} from 'src/components/TariffPlans/tariff-plans-context';
import {
  ActivePlansSet,
  TariffPlansType,
} from 'src/components/TariffPlans/types';

import { EvoBareMetalPlansSwiper } from './plansSwipers/EvoBareMetalPlansSwiper';
import { EvoCloudServerGpuPlansSwiper } from './plansSwipers/EvoCloudServerGpuPlansSwiper';
import { EvoCloudServerPlansSwiper } from './plansSwipers/EvoCloudServerPlansSwiper';
import { EvoKuberLandingPage } from './plansSwipers/EvoKuberLandingPage';
import { EvoKuberPlansSwiper } from './plansSwipers/EvoKuberPlansSwiper';
import { EvoPostgreSqlPlansSwiper } from './plansSwipers/EvoPostgreSqlPlansSwiper';
import { EvoStoragePlansSwiper } from './plansSwipers/EvoStoragePlansSwiper';
import s from './TariffPlans.module.scss';

/**
 * Что показываем на продуктовых страницах типа https://cloud.ru/products/evolution-compute
 */
function ProductPage({ title }: { title?: string }) {
  const state = useTariffPlansState();

  return (
    <div className="tarriffs-wrapper">
      <div className={s.wrapperProductPage}>
        {title && <h2 className={s.title}>{title}</h2>}
        <TimePeriodChanger />
        {state.activePlansSet === 'evoCloudServerMainPage' && (
          <EvoCloudServerPlansSwiper />
        )}
        {state.activePlansSet === 'evoKuberMainPage' && <EvoKuberPlansSwiper />}
        {state.activePlansSet === 'evoS3MainPage' && <EvoStoragePlansSwiper />}
        {state.activePlansSet === 'evoBareMetalMainPage' && (
          <EvoBareMetalPlansSwiper />
        )}
        {state.activePlansSet === 'evoCloudServerGpuMainPage' && (
          <EvoCloudServerGpuPlansSwiper />
        )}
        {state.activePlansSet === 'evoPostgreSqlMainPage' && (
          <EvoPostgreSqlPlansSwiper />
        )}
        {state.activePlansSet === 'evoKuberLandingPage' && (
          <EvoKuberLandingPage />
        )}
        <Disclaimer />
      </div>
    </div>
  );
}

/**
 * Что показываем на главной странице сайта.
 */
function MainPage({ title }: { title?: string }) {
  const state = useTariffPlansState();

  return (
    <div>
      <div className={s.wrapperMainPage}>
        {title && <h2 className={s.title}>{title}</h2>}
        <ProductChanger />
        {state.activePlansSet === 'evoCloudServerMainPage' && (
          <EvoCloudServerPlansSwiper />
        )}
        {state.activePlansSet === 'evoKuberMainPage' && <EvoKuberPlansSwiper />}
        {state.activePlansSet === 'evoS3MainPage' && <EvoStoragePlansSwiper />}
        <Disclaimer />
      </div>
    </div>
  );
}

function plansByProducts(
  initialActiveProduct: ProductType | 'evolutionKubernetesLandingPage',
): ActivePlansSet {
  switch (initialActiveProduct) {
    case 'evolutionCloudServer':
      return 'evoCloudServerMainPage';
    case 'evolutionKubernetesLandingPage':
      return 'evoKuberLandingPage';
    case 'evolutionKubernetes':
      return 'evoKuberMainPage';
    case 'evolutionStorageS3':
      return 'evoS3MainPage';
    case 'evolutionBareMetal':
      return 'evoBareMetalMainPage';
    case 'evolutionCloudServerGpu':
      return 'evoCloudServerGpuMainPage';
    case 'evolutionPostgreSql':
      return 'evoPostgreSqlMainPage';
    default:
      return 'evoCloudServerMainPage';
  }
}

interface MainProps {
  tariffPlansType: TariffPlansType;
  initialActivePlan: ActivePlansSet;
  title?: string;
}

function Main({ tariffPlansType, initialActivePlan, title }: MainProps) {
  const dispatch = useTariffPlansDispatch();

  useEffect(() => {
    dispatch({
      type: ACTIONS.UPD_ACTIVE_PLANS_SET,
      payload: initialActivePlan,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialActivePlan]);

  if (tariffPlansType === 'product') return <ProductPage title={title} />;
  if (tariffPlansType === 'mainPage') return <MainPage title={title} />;
}

interface TariffPlansProps {
  tariffPlansType?: TariffPlansType;
  initialActiveProduct?: ProductType | 'evolutionKubernetesLandingPage';
  title?: string;
  className?: string;
}

function TariffPlans({
  tariffPlansType = 'product',
  initialActiveProduct = 'evolutionCloudServer',
  className,
  title,
}: TariffPlansProps) {
  const wrapperClassName = cn(s.wrapper, className);
  return (
    <div className={wrapperClassName}>
      <TariffPlansProvider tariffPlansType={tariffPlansType}>
        <Main
          tariffPlansType={tariffPlansType}
          initialActivePlan={plansByProducts(initialActiveProduct)}
          title={title}
        />
      </TariffPlansProvider>
    </div>
  );
}

export { TariffPlans, type TariffPlansProps };
