import { adapter } from 'src/components/Calculator/utils/adapter';
import { ACTIONS } from 'src/components/TariffPlans/actions';
import { Slider } from 'src/components/TariffPlans/components/Slider';
import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';
import {
  useTariffPlansDispatch,
  useTariffPlansState,
} from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoPostgreSql,
  PlanIdPostgreSql,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';
import s from '../PlansSwipers.module.scss';

interface PlanCardProps {
  planId: PlanIdPostgreSql;
  plan: PlanEvoPostgreSql;
  planIndex: number;
  price: number;
  description: string;
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
  isActive: boolean;
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function EvoPostgreSqlPlanCard({
  planId,
  plan,
  planIndex,
  price,
  description,
  onClickOnConnect,
  currentPricePeriod,
  isActive,
}: PlanCardProps) {
  const dispatch = useTariffPlansDispatch();

  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType: ACTIONS.UPD_PLAN_EVOLUTION_POSTGRE_SQL_PRICE,
    statusActionType: ACTIONS.UPD_PLAN_EVOLUTION_POSTGRE_SQL_STATUS,
    productType: 'evolutionPostgreSql',
  });

  const onMouseOver = () =>
    dispatch({
      type: ACTIONS.UPD_ACTIVE_PLAN_INDEX,
      payload: planIndex,
    });

  const onMouseLeave = () =>
    dispatch({
      type: ACTIONS.UPD_ACTIVE_PLAN_INDEX,
      payload: 0,
    });

  const characteristics = [
    {
      title: 'Режим',
      value: `${adapter.renameEvoPostgreSqlDeploymentMode(
        plan.data.deploymentMode,
      )}`,
    },
    {
      title: 'Тип',
      value: `${capitalizeFirstLetter(plan.data.clusterType)}`,
    },
    {
      title: 'Количество vCPU, шт',
      value: `${plan.data.flavorConfig.vCpu}`,
    },
    {
      title: 'Объем RAM',
      value: `${plan.data.flavorConfig.ram} ГБ`,
    },
    {
      title: 'Диск',
      value: `${plan.data.systemDisk.diskSpace} ГБ ${plan.data.systemDisk.specification}`,
    },
  ];

  return (
    <PlanCard
      productType="evolutionPostgreSql"
      planStatus={planState?.planStatus}
      description={description}
      currentPricePeriod={currentPricePeriod}
      price={price}
      isActive={isActive}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
      tooltipText="4 000 бонусных рублей"
      tooltipHint="Получите 4000 бонусных рублей на 60 дней за привязку банковской карты"
    />
  );
}

function EvoPostgreSqlPlansSwiper() {
  const state = useTariffPlansState();
  const { connectProduct } = useSendConfigToConsole();

  const slides = state.plans[state.tariffPlansType].evolutionPostgreSql.map(
    (plan, index) => {
      return (
        <EvoPostgreSqlPlanCard
          key={plan.planId}
          planId={plan.planId}
          plan={plan}
          planIndex={index}
          price={plan.price[state.currentPricePeriod]}
          description={plan.description}
          onClickOnConnect={() => connectProduct('evolutionPostgreSql', plan)}
          currentPricePeriod={state.currentPricePeriod}
          isActive={state.activePlanIndex === index}
        />
      );
    },
  );

  return (
    <>
      <div className={s.slider}>
        <Slider
          slidesPerView={4}
          showButtons={false}
          slideClassName={s.slide}
          slidesSpacing={32}
        >
          {[
            ...slides,
            <CalcCard
              key="card-calculator"
              planIndex={slides.length}
              product="evolutionPostgreSql"
            />,
          ]}
        </Slider>
      </div>
      <div className={s.tariffWrapper}>
        {[
          ...slides,
          <CalcCard
            key="card-calculator"
            planIndex={slides.length}
            product="evolutionPostgreSql"
          />,
        ]}
      </div>
    </>
  );
}

export { EvoPostgreSqlPlansSwiper };
