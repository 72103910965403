import { useEffect } from 'react';
import {
  PriceActionType,
  StatusActionType,
} from 'src/components/TariffPlans/actions';
import {
  useTariffPlansDispatch,
  useTariffPlansPlanState,
} from 'src/components/TariffPlans/tariff-plans-context';
import {
  PlanId,
  PriceAction,
  StatusAction,
  TariffsProductType,
} from 'src/components/TariffPlans/types';

import { useGetConfigPrice } from './useGetConfigPrice';

type UseUpdatePlanCardPriceProps = {
  planId: PlanId;
  priceActionType: PriceActionType;
  statusActionType: StatusActionType;
  productType: TariffsProductType;
};

export function useUpdatePlanCardPrice({
  planId,
  priceActionType,
  statusActionType,
  productType,
}: UseUpdatePlanCardPriceProps) {
  const planState = useTariffPlansPlanState({ planId });
  const dispatch = useTariffPlansDispatch();

  const configPrice = useGetConfigPrice({
    productType: productType,
    productData: planState?.data,
  });

  useEffect(() => {
    if (configPrice) {
      dispatch({
        type: statusActionType,
        payload: {
          planId: planId,
          planStatus: 'success',
        },
      } as StatusAction);

      dispatch({
        type: priceActionType,
        payload: {
          planId: planId,
          price: configPrice.price,
        },
      } as PriceAction);
    } else {
      dispatch({
        type: statusActionType,
        payload: {
          planId: planId,
          planStatus: 'error',
        },
      } as StatusAction);
    }
  }, [configPrice, dispatch, planId, priceActionType, statusActionType]);

  return { planState };
}
