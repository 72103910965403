import { ACTIONS } from 'src/components/TariffPlans/actions';
import { Slider } from 'src/components/TariffPlans/components/Slider';
import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';
import {
  useTariffPlansDispatch,
  useTariffPlansState,
} from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoBareMetal,
  PlanIdBareMetal,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';
import {
  getTotalCpu,
  getTotalDisk,
  getTotalRam,
} from '../../utils/evoBareMetalPlansSwiper';
import s from '../PlansSwipers.module.scss';

interface PlanCardProps {
  plan: PlanEvoBareMetal;
  planIndex: number;
  price: number;
  description: string;
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
  isActive: boolean;
}

function EvoBareMetalPlanCard({
  plan,
  planIndex,
  price,
  description,
  onClickOnConnect,
  currentPricePeriod,
  isActive,
}: PlanCardProps) {
  const {
    planId,
    data: { nameFlavour },
  } = plan;
  const dispatch = useTariffPlansDispatch();

  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType: ACTIONS.UPD_PLAN_EVOLUTION_BARE_METAL_PRICE,
    statusActionType: ACTIONS.UPD_PLAN_EVOLUTION_BARE_METAL_STATUS,
    productType: 'evolutionBareMetal',
  });

  const { 1: ram, 2: disk } = nameFlavour.split(';').map((el) => el.trim());

  const onMouseOver = () =>
    dispatch({
      type: ACTIONS.UPD_ACTIVE_PLAN_INDEX,
      payload: planIndex,
    });

  const onMouseLeave = () =>
    dispatch({
      type: ACTIONS.UPD_ACTIVE_PLAN_INDEX,
      payload: 0,
    });

  const characteristics = [
    {
      title: 'Процессор (CPU)',
      value: getTotalCpu(planId),
    },
    {
      title: 'Оперативная память (RAM)',
      value: getTotalRam(ram),
    },
    {
      title: 'Диски',
      value: getTotalDisk(disk),
    },
    {
      title: 'IP-адрес',
      value: '1 шт',
    },
  ];

  return (
    <PlanCard
      productType="evolutionBareMetal"
      description={description}
      planStatus={planState?.planStatus}
      currentPricePeriod={currentPricePeriod}
      price={price}
      isActive={isActive}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
    />
  );
}

function EvoBareMetalPlansSwiper() {
  const state = useTariffPlansState();
  const { connectProduct } = useSendConfigToConsole();

  const slides = state.plans[state.tariffPlansType].evolutionBareMetal.map(
    (plan, index) => {
      return (
        <EvoBareMetalPlanCard
          key={plan.planId}
          plan={plan}
          planIndex={index}
          price={plan.price[state.currentPricePeriod]}
          description={plan.description}
          onClickOnConnect={() => connectProduct('evolutionBareMetal', plan)}
          currentPricePeriod={state.currentPricePeriod}
          isActive={state.activePlanIndex === index}
        />
      );
    },
  );

  return (
    <>
      <div className={s.slider}>
        <Slider
          slidesPerView={4}
          showButtons={false}
          slideClassName={s.slide}
          slidesSpacing={32}
        >
          {[
            ...slides,
            <CalcCard
              key="card-calculator"
              planIndex={slides.length}
              product="evolutionBareMetal"
            />,
          ]}
        </Slider>
      </div>
      <div className={s.tariffWrapper}>
        {[
          ...slides,
          <CalcCard
            key="card-calculator"
            planIndex={slides.length}
            product="evolutionBareMetal"
          />,
        ]}
      </div>
    </>
  );
}

export { EvoBareMetalPlansSwiper };
