import { ACTIONS } from 'src/components/TariffPlans/actions';
import { Slider } from 'src/components/TariffPlans/components/Slider';
import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';
import {
  useTariffPlansDispatch,
  useTariffPlansState,
} from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoCloudServer,
  PlanIdCloudServer,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';
import s from '../PlansSwipers.module.scss';

interface PlanCardProps {
  planId: PlanIdCloudServer;
  planIndex: number;
  price: number;
  description: string;
  guaranteedPart: PlanEvoCloudServer['data']['guaranteedPart'];
  vCpuCoreCount: PlanEvoCloudServer['data']['vCpuCoreCount'];
  ramAmount: PlanEvoCloudServer['data']['ramAmount'];
  systemDiskDiskSpace: PlanEvoCloudServer['data']['evs']['systemDisk']['diskSpace'];
  networkIsNeeded: PlanEvoCloudServer['data']['networkIsNeeded'];
  onClickNetworkIsNeeded: () => void;
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
  isActive: boolean;
}

function EvoCloudServerPlanCard({
  planId,
  planIndex,
  price,
  description,
  guaranteedPart,
  vCpuCoreCount,
  ramAmount,
  systemDiskDiskSpace,
  networkIsNeeded,
  onClickNetworkIsNeeded,
  onClickOnConnect,
  currentPricePeriod,
  isActive,
}: PlanCardProps) {
  const dispatch = useTariffPlansDispatch();

  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_PRICE,
    statusActionType: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_STATUS,
    productType:
      planId === 'free-tier'
        ? 'evolutionCloudServerFreeTier'
        : 'evolutionCloudServer',
  });

  const onMouseOver = () =>
    dispatch({
      type: ACTIONS.UPD_ACTIVE_PLAN_INDEX,
      payload: planIndex,
    });

  const onMouseLeave = () =>
    dispatch({
      type: ACTIONS.UPD_ACTIVE_PLAN_INDEX,
      payload: 0,
    });

  const characteristics = [
    {
      title: 'Гарантированная доля vCPU',
      value: `${guaranteedPart}% доля`,
    },
    {
      title: 'Количество vCPU, шт',
      value: `${vCpuCoreCount} vCPU`,
    },
    {
      title: 'Объем RAM',
      value: `${ramAmount} Гб`,
    },
    {
      title: 'Диск NVMe',
      value: `${systemDiskDiskSpace} Гб`,
    },
  ];

  return (
    <PlanCard
      productType={
        planId === 'free-tier'
          ? 'evolutionCloudServerFreeTier'
          : 'evolutionCloudServer'
      }
      isFreeTier={planId === 'free-tier'}
      planStatus={planState?.planStatus}
      description={description}
      currentPricePeriod={currentPricePeriod}
      price={price}
      isActive={isActive}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
      onCheckboxClick={onClickNetworkIsNeeded}
      checkBoxText="Добавить IP-адрес"
      checkBoxActive={networkIsNeeded}
      tooltipText="4 000 бонусных рублей"
      tooltipHint="Получите 4000 бонусных рублей на 60 дней за привязку банковской карты"
    />
  );
}

function EvoCloudServerPlansSwiper() {
  const dispatch = useTariffPlansDispatch();
  const state = useTariffPlansState();

  const { connectProduct } = useSendConfigToConsole();

  const slides = state.plans[state.tariffPlansType].evolutionCloudServer.map(
    (plan, index) => {
      return (
        <EvoCloudServerPlanCard
          key={plan.planId}
          planId={plan.planId}
          planIndex={index}
          price={plan.price[state.currentPricePeriod]}
          description={plan.description}
          guaranteedPart={plan.data.guaranteedPart}
          vCpuCoreCount={plan.data.vCpuCoreCount}
          ramAmount={plan.data.ramAmount}
          systemDiskDiskSpace={plan.data.evs.systemDisk.diskSpace}
          networkIsNeeded={plan.data.networkIsNeeded}
          onClickNetworkIsNeeded={() =>
            dispatch({
              type: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_INPUTS,
              payload: {
                planId: plan.planId,
                data: {
                  networkIsNeeded: !plan.data.networkIsNeeded,
                },
              },
            })
          }
          onClickOnConnect={() =>
            connectProduct(
              plan.planId === 'free-tier'
                ? 'evolutionCloudServerFreeTier'
                : 'evolutionCloudServer',
              plan,
            )
          }
          currentPricePeriod={state.currentPricePeriod}
          isActive={state.activePlanIndex === index}
        />
      );
    },
  );

  return (
    <>
      <div className={s.slider}>
        <Slider
          slidesPerView={4}
          showButtons={false}
          slideClassName={s.slide}
          slidesSpacing={32}
        >
          {[
            ...slides,
            <CalcCard
              key="card-calculator"
              planIndex={slides.length}
              product="evolutionCloudServer"
            />,
          ]}
        </Slider>
      </div>
      <div className={s.tariffWrapper}>
        {[
          ...slides,
          <CalcCard
            key="card-calculator"
            planIndex={slides.length}
            product="evolutionCloudServer"
          />,
        ]}
      </div>
    </>
  );
}

export { EvoCloudServerPlansSwiper };
