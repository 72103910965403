import { ReactNode } from 'react';
import { CarouselBase } from 'src/components/Carousel/CarouselBase';

import s from './Slider.module.scss';

function Arrow() {
  return (
    <svg
      className={s.arrow}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0839 33.33L13 31.2233L24.5583 19.665L13 8.10667L15.0839 6L28.7489 19.665L15.0839 33.33Z"
        fill="#4E4E4E"
      />
    </svg>
  );
}

interface SliderProps {
  slidesPerView?: number;
  children: ReactNode[];
  slideClassName?: string;
  showButtons?: boolean;
  slidesSpacing: number;
}

function Slider({
  slidesPerView = 4,
  showButtons = true,
  children,
  slideClassName,
  slidesSpacing,
}: SliderProps) {
  return (
    <section className={s.root}>
      <div className={s.carouselWrapper}>
        <CarouselBase
          rewind
          slidesPerView={slidesPerView}
          slidesSpacing={slidesSpacing}
          slidesCount={4}
          withNavigation={showButtons}
          withPagination
          paginationBulletClassName={s.bullet}
          carouselClassName={s.carousel}
          navigationClassName={s.buttons}
          arrowClassName={s.arrow}
          customArrow={<Arrow />}
          slideClassName={slideClassName}
          breakpoints={{
            0: {
              slidesPerView: 1.3,
            },
            435: {
              slidesPerView: 1.5,
            },
            500: {
              slidesPerView: 1.6,
            },
            530: {
              slidesPerView: 1.8,
            },
            590: {
              slidesPerView: 2,
            },
            650: {
              slidesPerView: 2.2,
            },
            720: {
              slidesPerView: 2.4,
            },
            829: {
              slidesPerView: 4,
            },
          }}
        >
          {children}
        </CarouselBase>
      </div>
    </section>
  );
}

export { Slider };
