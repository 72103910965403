import cn from 'classnames';
import { ACTIONS } from 'src/components/TariffPlans/actions';
import {
  useTariffPlansDispatch,
  useTariffPlansState,
} from 'src/components/TariffPlans/tariff-plans-context';

import s from './styles.module.scss';

function TimePeriodChanger() {
  const dispatch = useTariffPlansDispatch();
  const { currentPricePeriod, activePlansSet } = useTariffPlansState();

  if (activePlansSet === 'evoBareMetalMainPage') {
    return (
      <div className={s.periods}>
        <div className={s.periodsButtons}>
          <div className={cn(s.period, s.periodActive)} onClick={() => {}}>
            Месяц
          </div>
        </div>
        <div>Период расчета стоимости</div>
      </div>
    );
  }

  return (
    <div className={s.periods}>
      <div className={s.periodsButtons}>
        <div
          className={cn(s.period, {
            [s.periodActive]: currentPricePeriod === 'priceHourNds',
          })}
          onClick={() =>
            dispatch({
              type: ACTIONS.UPD_CURRENT_PRICE_PERIOD,
              payload: 'priceHourNds',
            })
          }
        >
          Час
        </div>
        <div
          className={cn(s.period, {
            [s.periodActive]: currentPricePeriod === 'priceDayNds',
          })}
          onClick={() =>
            dispatch({
              type: ACTIONS.UPD_CURRENT_PRICE_PERIOD,
              payload: 'priceDayNds',
            })
          }
        >
          День
        </div>
        <div
          className={cn(s.period, {
            [s.periodActive]: currentPricePeriod === 'priceMonthNds',
          })}
          onClick={() =>
            dispatch({
              type: ACTIONS.UPD_CURRENT_PRICE_PERIOD,
              payload: 'priceMonthNds',
            })
          }
        >
          Месяц
        </div>
      </div>
      <div>Период расчета стоимости</div>
    </div>
  );
}

export { TimePeriodChanger };
