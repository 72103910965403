import { Price } from 'src/components/TariffPlans/types';

// Общие //
const price = {
  priceHourNds: 0,
  priceDayNds: 0,
  priceMonthNds: 0,
} as Price;

export { price };
