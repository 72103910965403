import { dataTestAttrProp } from 'src/utils/dataTestAttrProp';

import s from './Disclaimer.module.scss';

export function Disclaimer() {
  return (
    <div className={s.desc} {...dataTestAttrProp('disclaimer-block')}>
      <p
        className={s.text}
        {...dataTestAttrProp('disclaimer-block-primary-text')}
      >
        ООО «Облачные технологии» не хранит, не распространяет, не передает
        третьим лицам и не использует внесенные пользователем данные ни в каком
        виде. Калькулятор обрабатывает лишь техническую информацию, необходимую
        для расчета и не соотносит ее с личностью пользователя и/или его
        персональными данными.
      </p>
      <p
        className={s.text}
        {...dataTestAttrProp('disclaimer-block-secondary-text')}
      >
        Рассчитываемая Калькулятором стоимость является предварительной,
        основанной на внесенных пользователем данных, и не может расцениваться
        как предложение заключить договор по определенной Калькулятором цене (не
        является офертой).
      </p>
    </div>
  );
}
