import { ACTIONS } from 'src/components/TariffPlans/actions';
import { Slider } from 'src/components/TariffPlans/components/Slider';
// import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';`
import {
  useTariffPlansDispatch,
  useTariffPlansState,
} from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoCloudServerGpu,
  PlanIdCloudServerGpu,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';
import s from '../PlansSwipers.module.scss';

interface PlanCardProps {
  planId: PlanIdCloudServerGpu;
  planIndex: number;
  price: number;
  description: string;
  plan: PlanEvoCloudServerGpu;
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
  isActive: boolean;
}

function EvoCloudServerGpuPlanCard({
  planId,
  planIndex,
  price,
  plan,
  onClickOnConnect,
  currentPricePeriod,
  isActive,
}: PlanCardProps) {
  const dispatch = useTariffPlansDispatch();

  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_PRICE,
    statusActionType: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_STATUS,
    productType: 'evolutionCloudServerGpu',
  });

  const onMouseOver = () =>
    dispatch({
      type: ACTIONS.UPD_ACTIVE_PLAN_INDEX,
      payload: planIndex,
    });

  const onMouseLeave = () =>
    dispatch({
      type: ACTIONS.UPD_ACTIVE_PLAN_INDEX,
      payload: 0,
    });

  const characteristics = [
    {
      title: 'Графический процессор',
      value: `${plan.data.gpuType}`,
    },
    {
      title: 'Количество GPU, шт',
      value: `${plan.data.gpuCount}`,
    },
    {
      title: 'Количество vCPU, шт',
      value: `${plan.data.vCpuCoreCount}`,
    },
    {
      title: 'Объем RAM',
      value: `${plan.data.ramAmount}`,
    },
    {
      title: `Диск ${plan.data.evs.systemDisk.specification}`,
      value: `${plan.data.evs.systemDisk.diskSpace}`,
    },
  ];

  const onClickNetworkIsNeeded = () =>
    dispatch({
      type: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_INPUTS,
      payload: {
        planId: plan.planId,
        data: {
          networkIsNeeded: !plan.data.networkIsNeeded,
        },
      },
    });

  return (
    <PlanCard
      productType="evolutionCloudServerGpu"
      planStatus={planState?.planStatus}
      currentPricePeriod={currentPricePeriod}
      price={price}
      isActive={isActive}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
      onCheckboxClick={onClickNetworkIsNeeded}
      checkBoxText="Добавить IP-адрес"
      checkBoxActive={plan.data.networkIsNeeded}
    />
  );
}

function EvoCloudServerGpuPlansSwiper() {
  const state = useTariffPlansState();
  // const { connectProduct } = useSendConfigToConsole();

  const slides = state.plans[state.tariffPlansType].evolutionCloudServerGpu.map(
    (plan, index) => {
      return (
        <EvoCloudServerGpuPlanCard
          key={plan.planId}
          planId={plan.planId}
          plan={plan}
          planIndex={index}
          price={plan.price[state.currentPricePeriod]}
          description={plan.description}
          // пока что оставляем так, тк нету продукта который можно было бы подключить в ЛК
          onClickOnConnect={() => {}}
          currentPricePeriod={state.currentPricePeriod}
          isActive={state.activePlanIndex === index}
        />
      );
    },
  );

  return (
    <>
      <div className={s.slider}>
        <Slider
          slidesPerView={4}
          showButtons={false}
          slideClassName={s.slide}
          slidesSpacing={32}
        >
          {[
            ...slides,
            <CalcCard
              key="card-calculator"
              planIndex={slides.length}
              product="evolutionCloudServerGpu"
            />,
          ]}
        </Slider>
      </div>
      <div className={s.tariffWrapper}>
        {[
          ...slides,
          <CalcCard
            key="card-calculator"
            planIndex={slides.length}
            product="evolutionCloudServerGpu"
          />,
        ]}
      </div>
    </>
  );
}

export { EvoCloudServerGpuPlansSwiper };
