import { numberFormat } from 'src/components/Calculator/utils/numberFormat';
import { ACTIONS } from 'src/components/TariffPlans/actions';
import { Slider } from 'src/components/TariffPlans/components/Slider';
import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';
import {
  useTariffPlansDispatch,
  useTariffPlansState,
} from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoStorage,
  PlanIdStorage,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';
import s from '../PlansSwipers.module.scss';

interface PlanCardProps {
  planId: PlanIdStorage;
  planIndex: number;
  price: number;
  description: string;
  apiPut: PlanEvoStorage['data']['apiPut'];
  apiPost: PlanEvoStorage['data']['apiPost'];
  storageSize: PlanEvoStorage['data']['storageSize'];
  storageType: PlanEvoStorage['data']['storageType'];
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
  isActive: boolean;
}

const storageTypeObj = {
  cold: 'Холодное',
  ice: 'Ледяное',
  standart: 'Стандартное',
  single: 'Однозонное',
};

function EvoStoragePlanCard({
  planId,
  planIndex,
  price,
  description,
  apiPut,
  apiPost,
  storageSize,
  storageType,
  onClickOnConnect,
  currentPricePeriod,
  isActive,
}: PlanCardProps) {
  const dispatch = useTariffPlansDispatch();

  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType: ACTIONS.UPD_PLAN_EVOLUTION_STORAGE_S3_PRICE,
    statusActionType: ACTIONS.UPD_PLAN_EVOLUTION_STORAGE_S3_STATUS,
    productType:
      planId === 'free-tier'
        ? 'evolutionStorageS3FreeTier'
        : 'evolutionStorageS3',
  });

  const onMouseOver = () =>
    dispatch({
      type: ACTIONS.UPD_ACTIVE_PLAN_INDEX,
      payload: planIndex,
    });

  const onMouseLeave = () =>
    dispatch({
      type: ACTIONS.UPD_ACTIVE_PLAN_INDEX,
      payload: 0,
    });

  const characteristics = [
    {
      title: 'Тип хранилища',
      value: `${storageTypeObj[storageType]}`,
    },
    {
      title: 'Объем хранилища',
      value: `${numberFormat(storageSize)} Гб`,
    },
    {
      title: 'PUT-операции',
      value: `${numberFormat(apiPut)} шт`,
    },
    {
      title: 'POST-операции',
      value: `${numberFormat(apiPost)} шт`,
    },
  ];

  return (
    <PlanCard
      productType={
        planId === 'free-tier'
          ? 'evolutionStorageS3FreeTier'
          : 'evolutionStorageS3'
      }
      isFreeTier={planId === 'free-tier'}
      planStatus={planState?.planStatus}
      description={description}
      currentPricePeriod={currentPricePeriod}
      price={price}
      isActive={isActive}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
      tooltipText="4 000 бонусных рублей"
      tooltipHint="Получите 4000 бонусных рублей на 60 дней за привязку банковской карты"
    />
  );
}

function EvoStoragePlansSwiper() {
  const state = useTariffPlansState();
  const { connectProduct } = useSendConfigToConsole();

  const slides = state.plans[state.tariffPlansType].evolutionStorageS3.map(
    (plan, index) => {
      return (
        <EvoStoragePlanCard
          key={plan.planId}
          planId={plan.planId}
          planIndex={index}
          price={plan.price[state.currentPricePeriod]}
          description={plan.description}
          apiPut={plan.data.apiPut}
          apiPost={plan.data.apiPost}
          storageSize={plan.data.storageSize}
          storageType={plan.data.storageType}
          onClickOnConnect={() =>
            connectProduct(
              plan.planId === 'free-tier'
                ? 'evolutionStorageS3FreeTier'
                : 'evolutionStorageS3',
              plan,
            )
          }
          currentPricePeriod={state.currentPricePeriod}
          isActive={state.activePlanIndex === index}
        />
      );
    },
  );

  return (
    <>
      <div className={s.slider}>
        <Slider
          slidesPerView={4}
          showButtons={false}
          slideClassName={s.slide}
          slidesSpacing={32}
        >
          {[
            ...slides,
            <CalcCard
              key="card-calculator"
              planIndex={slides.length}
              product="evolutionStorageS3"
            />,
          ]}
        </Slider>
      </div>
      <div className={s.tariffWrapper}>
        {[
          ...slides,
          <CalcCard
            key="card-calculator"
            planIndex={slides.length}
            product="evolutionStorageS3"
          />,
        ]}
      </div>
    </>
  );
}

export { EvoStoragePlansSwiper };
